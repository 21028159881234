"use client";

import { Button, Heading, Image, Link, Stack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function Error({ error, reset }) {
  const { t } = useTranslation();

  useEffect(() => {
    console.log(error);
  }, [error]);

  return (
    <Stack
      spacing={6}
      role="alert"
      w="full"
      h="100vh"
      justify="center"
      align="center"
    >
      <Link href="/">
        <Image src="/beauti_bucks_logo.jpeg" h="60px" alt="logo" />
      </Link>
      <Heading color="primary.500">
        {t("Oops!!Something went wrong!!!")}
      </Heading>
      <Button onClick={reset} colorScheme="gray">
        {t("Try again")}
      </Button>
    </Stack>
  );
}
